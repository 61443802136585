<template>
    <div class="page">
        <div class="title">员工借支明细</div>
		
        <!-- filter -->
        <div class="filter_box">
            <div class="filter_search">
                <!-- picker -->
                <el-date-picker
                    v-model="search.date"
                    type="month"
                    value-format="yyyy-MM"
                ></el-date-picker>

                <!-- input -->
                <el-input
                    class="filter_search_input"
                    placeholder="请输入关键词"
                    v-model="search.keyword"
                    clearable
                >
                </el-input>
                <div class="custom_button no_select" @click="toSearch">搜索</div>
            </div>
            <div class="filter_btns">
                <div
                    class="custom_button plain no_select"
                    @click="dialogVisible = true"
                >
                    添加借支
                </div>
                <div class="custom_button no_select">导出</div>
            </div>
        </div>

        <!-- table -->
        <el-table
            id="table"
            :data="tableData"
            :header-cell-style="tableHeaderStyle"
            :cell-style="tableCellStyle"
            style="width: 100%;"
        >
            <el-table-column
                prop="staff.job_number"
                label="工号"
            ></el-table-column>
            <el-table-column
                prop="staff.real_name"
                label="姓名"
            ></el-table-column>
            <el-table-column
                prop="staff.department_data.department_name"
                label="部门"
            ></el-table-column>
            <el-table-column
                prop="borrow_amount"
                label="借支金额"
            ></el-table-column>
            <el-table-column
                prop="borrow_time"
                label="借支时间"
            ></el-table-column>
            <el-table-column
                prop="borrow_explain"
                label="借支说明"
            ></el-table-column>
            <el-table-column
                prop="status_value"
                label="归还状态"
            ></el-table-column>
            <el-table-column label="操作" min-width="180" fixed="right">
                <template slot-scope="scope">
                    <el-button
                        v-if="scope.row.status != 1"
                        @click.native.prevent="check(scope.row.id)"
                        type="text"
                        size="small"
                        style="color: #e50112;"
                    >
                        确认归还
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            layout="prev, pager, next"
            :total="page.total"
            :page-size="page.size"
            :current-page.sync="page.page"
            @current-change="loadData"
        >
        </el-pagination>

        <el-dialog
            title="添加员工借支"
            :visible.sync="dialogVisible"
            width="30%"
        >
            <el-form :model="form" ref="form" :rules="rules">
                <el-form-item label="员工名称" prop="staff_id" v-if="!form.id">
                    <el-select
                        v-model="form.staff_id"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="请输入面员工姓名搜索"
                        :remote-method="remoteMethod"
                        :loading="employee_loading"
                    >
                        <el-option
                            v-for="item in employee_options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="借支金额" prop="borrow_amount">
                    <el-input
                        v-model="form.borrow_amount"
                        type="number"
                    ></el-input>
                </el-form-item>
                <el-form-item label="说明" prop="borrow_explain">
                    <el-input v-model="form.borrow_explain"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <div class="custom_button no_select" @click="addSubmit">
                    确 定
                </div>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    data() {
        return {
            search: {
                keyword: "",
                date: this.$options.filters.formatTime(new Date(), "yyyy-MM"),
            },
            tableData: [],
            dialogVisible: false,
            employee_options: [],
            employee_loading: false,
            form: {
                staff_id: "",
                borrow_amount: "",
                borrow_explain: "",
            },
            rules: {
                staff_id: [
                    {
                        required: true,
                        message: "请选择员工",
                        trigger: "change",
                    },
                ],
                borrow_amount: [
                    {
                        required: true,
                        message: "请输入借支金额",
                        trigger: "blur",
                    },
                    {
                        pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
                        message: "请输入正确的借支金额",
                        trigger: "blur",
                    },
                ],
            },
            page: {
                size: 15,
                page: 1,
                total: 0,
            },

            tableHeaderStyle: {
                backgroundColor: "#F5F8FA",
                textAlign: "center",
            },
            tableCellStyle: {
                textAlign: "center",
            },
        };
    },
    created() {
        this.loadData((loadDataObj) => {
            loadDataObj.month = this.search.date;
        });
    },
    methods: {
        ...mapActions("employee", ["getEmployeeList"]),
        ...mapActions("pay", [
            "getSalaryBorrowList",
            "postSalaryBorrowBack",
            "addSalaryBorrow",
        ]),

        loadData(func) {
            // 单例初始化
            if (!this.loadDataObj)
                this.loadDataObj = new LoadDataClass(
                    0,
                    this.getSalaryBorrowList
                );

            // 通知，用于修改对象属性
            if (func) func(this.loadDataObj);

            let loading = this.$loading();
            this.loadDataObj
                .load(this.page.page)
                .then((res) => {
                    console.log(res);
                    this.tableData = res.data.data.list;
                    this.page.total = res.data.data.total;
                    loading.close();
                })
                .catch((e) => {
                    loading.close();
                    this.$message.error(e);
                });
        },

        remoteMethod(query) {
            if (query !== "") {
                this.employee_loading = true;
                this.getEmployeeList({
                    page: 1,
                    keywords: query,
                    status: 3,
                })
                    .then((res) => {
                        this.employee_loading = false;
                        console.log(res);
                        this.employee_options = res.data.data.list.map(
                            (item) => {
                                return {
                                    label: item.real_name,
                                    value: item.id,
                                };
                            }
                        );
                    })
                    .catch((e) => {
                        this.employee_loading = false;
                        console.error(e);
                    });
            } else {
                this.employee_options = [];
            }
        },

        addSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let loading = this.$loading();
                    this.dialogVisible = false;
                    this.addSalaryBorrow(this.form)
                        .then((res) => {
                            console.log(res);
                            loading.close();
                            this.$message.success(res.data.msg);
                            this.loadData();
                        })
                        .catch((e) => {
                            console.log(e);
                            loading.close();
                            this.$message.error(e.msg);
                        });
                } else {
                    this.$notify.error({
                        title: "提交失败",
                        message: "请先完整填写表单",
                    });
                    return false;
                }
            });
        },

        /**
         * 确认归还
         */
        check(id) {
            this.$prompt("请输入归还的金额", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                inputPattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
                inputErrorMessage: "输入金额不正确",
            }).then(({ value }) => {
                let loading = this.$loading();
                this.postSalaryBorrowBack({
                    id,
                    back_amount: value,
                })
                    .then((res) => {
                        console.log(res);
                        loading.close();
                        this.$message({
                            type: "success",
                            message: res.data.msg,
                        });
                        this.loadData();
                    })
                    .catch((e) => {
                        console.log(e);
                        loading.close();
                        this.$message.error(e.msg);
                    });
            });
        },

        /**
         * 搜索
         */
        toSearch() {
            this.page.page = 1;
            this.loadData((loadDataObj) => {
                loadDataObj.keyword = this.search.keyword;
                loadDataObj.month = this.search.date;
            });
        },
    },
};

class LoadDataClass {
    constructor(month, request, keyword = null) {
        this.month = month;
        this.keyword = keyword;
        this.func = request;
    }

    load(page) {
        let params = {
            month: this.month,
            page,
        };
        if (this.keyword) params.keyword = this.keyword;
        return this.func(params);
    }
}
</script>

<style lang="scss" scoped>
.title {
    font-size: 14px;
    font-weight: bold;
    color: #1c2438;
}

.filter_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;

    .el-date-editor {
        height: 36px;
        margin-right: 10px;

        ::v-deep .el-input__inner {
            height: 36px;
        }
    }

    .filter_search {
        display: flex;
        align-items: center;

        .filter_search_input {
            width: 350px;
            height: 36px;
            margin-right: 10px;

            ::v-deep .el-input__inner {
                height: 36px;
            }
        }
    }

    .filter_btns {
        display: flex;
        align-items: center;

        > div:not(:first-child) {
            margin-left: 20px;
        }
    }
}

.operations {
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
        font-size: 14px;
        color: #406bff;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 13px;
        }
    }
}
</style>
